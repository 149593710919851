@import "../../colors.scss";
@import "../../fonts.scss";


.bread-crumb{

  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 10px;
  background: #e8e8e8;

  a{
    display: inline-block;
    @include fonts__sans-serif;
    font-size: 14px;
    margin: 5px 0;
    text-decoration: none;
    color: #000;
    @media only screen and (max-width: 600px) {
      font-size: 13px;
    }
    &:hover{
      text-decoration: underline;
    }
    .dash{
      margin: 0 10px;
    }
  }
}

.product-detail {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  margin-bottom: 3rem;


  /** mobile */
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }

  .video-container{
    position: absolute;
    top: 0;
    z-index: 2;
    display: flex;
    width: 100%;

    // background: #000000c2;

    box-shadow: 0 2px 8px rgba(0,0,0,.3);
    overflow: hidden;
    background: rgb(255 255 255 / 93%);

    @media only screen and (max-width: 600px) {
     // height: 100vh;

    }


    video{
      display: none;
      // max-width: 970px;
      width: 100%;
      max-height: 630px;
      min-height: 360px;
      position: relative;
      // overflow: hidden;
     // display: inline-block;
      cursor: zoom-in;
    }

    .video-close-btn{
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      display: none;

      svg{
        width: 50px;
        height: 50px;
        color: #cccccc;

        &:hover{
          color: $color_orange;
        }
      }
    }
  }

  .img-container {
    
    padding: 10px;
    border-right: 1px solid #e5e5e5;


    @media only screen and (max-width: 600px) {
      border-right: none;
      width: 100%;
    }

  }
 
  h3 {
    margin-bottom: 5px;
  }

  .desktop-head, .head-desktop {
    display: block;

    h1{
      font-size: 1.7em;
    }

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  .mobile-head {
    padding: 20px 10px 10px;
    display: none;

    h3, h1 {
      margin: 0;
      font-size: 1.5em;
    }

    @media only screen and (max-width: 600px) {
      display: block;
      width: 100%;
    }
  }


  .content {
    max-width: 450px;
    text-align: left;
    padding: 10px;
    position: relative;

    img{
      width: 100%;
    }

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
   
    .item-added-alert {
      pointer-events: none;
      position: absolute;
      z-index: 3;
      left: 40%;
      top: 8%;
      background: #555555;
      padding: 10px;
      color: #fff;
      border-radius: 4px;
      transition: all 1s;
      opacity: 0;

      @media only screen and (max-width: 600px) {
        position: fixed;
        left: 30%;
        top: 50%;
      }

    }

    hr {
      height: 1px;
      background-color: rgb(222, 222, 222);
      border: none;
    }

    .in-stock {
      @include fonts__sans-serif;
      font-size: 14px;
      margin: 0 0 5px 0;
      border: 1px solid #e8b510;
      max-width: 63px;
      padding: 4px 5px;
      text-align: center;
      border-radius: 4px;
    }

    .add-buy-btn-group {

      @media only screen and (max-width: 600px) {
        display: flex;
        justify-content: center;
        width: 100%;

        button {
          &:nth-child(2) {
            margin-right: 0;
          }
        }
      }

    }

    #btns-fixed {
      //display: none;
    }

    .add-buy-btn-group-fixed {
      display: none;
      position: fixed;
      bottom: 0;
      z-index: 9;
      width: 94%;
      justify-content: center;
      background: #fff;

      button {
        &:nth-child(2) {
          margin-right: 0;
        }
      }

      @media only screen and (max-width: 600px) {
        display: flex;
        //width: 100%;
      }
    }

    .product-detail-head {
      margin-top: 2rem;
    }



    .snipcart-add-item {
      @include fonts__sans-serif;
      width: 130px;
      margin-right: 20px;
      margin-bottom: 10px;
      margin-top: 10px;
      // text-transform: uppercase;
      font-size: 17px;
      // background-color: $color_brand_gold;

      &:hover {
        background-color: $color_brand_gold_over;
      }
    }


    .description {
      max-height: 400px;
      position: relative;
      overflow: hidden;

      .desc-content {
        line-height: 24px;

        p {
          //font-size: 14px;
          line-height: 24px;
          margin-top: 0;
        }
      }

      .read-more {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        margin: 0;
        padding: 30px 0;
        padding: 70px 0 0px;
        /* "transparent" only works here because == rgba(0,0,0,0) */
        background-image: linear-gradient(to bottom, transparent, #fff);

        a {
          @include fonts__sans-serif;
          text-decoration: none;
          text-transform: uppercase;
          font-size: 13px;
          // border: 1px solid grey;
          padding: 3px 6px;
          position: relative;
          background: #fff;
          border-radius: 3px;
          top: -6px;
          color: #fff;
          background: #e9b50f;

          // &:hover {
          //   background: rgb(198, 198, 198);
          // }

          // &:visited {
          //   color: #fff;
          // }
        }
      }

    }

    .shipping-detail{

      margin: 10px 0;
      
      h3{
        @include fonts__sans-serif;
        font-size: 15px;
        margin: 20px 0 10px;
      }

      p{
        @include fonts__sans-serif;
        color: #666666;
        font-size: 14px;
        margin: 4px 0;

        .fast-delivery-option{
          color: rgb(0, 169, 0);
          font-weight: 700;
        }
      }
      ul{
        li{
          display: block;
        }
      }

      .fast-shipping{
        margin-right: 5px;
        svg{
          width: 16px;
          height: auto;
          fill: #00a800;
          vertical-align: sub;
        }
      }
    }
  }

  .content-desc {
    flex-basis: 100%;
    width: 100%;
    max-width: 100%;

    .content {
      width: 100%;
      max-width: 1050px;
      margin: 1.5rem auto;
      border-top: 1px solid rgb(222, 222, 222);

      p {
        word-break: break-all;
      }

      img {
        max-width: 100%;
        @media only screen and (max-width: 600px) {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.pd-loader{
  width: 100%;
  height: 59vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-holder{
  position: fixed;
  top:0;
  left: 50px;
}

.snipcart-item-description {

  display: none;

}


.detailmodule_text p, .detailmodule_html p {
  text-align: left !important;
}