@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");
@import "../../colors.scss";
@import "../../fonts.scss";



.loader {
  margin: 0 auto;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin {

  .admin-content {
    display: flex;
    align-items: baseline;
    border: 1px dashed rgb(211, 211, 211);
    margin: 10px auto;
    max-width: 1256px;

    @media only screen and (max-width: 1217px) {
      flex-direction: column;
    }

    
    &__item {
      
      height: 50px;
      display: flex;
      align-items: center;
      padding: 15px 20px;

      border-right: 1px dashed rgb(211, 211, 211);

      @media only screen and (max-width: 1217px) {
        border-right:none;
      }

      .select-block {

        display: flex;
        align-items: baseline;


        h3 {
          margin: 0;
        }

        select {
          @include fonts__sans-serif;
          padding: 0 10px;
          height: 32px;
          /* font-size: 13px; */
          line-height: 14px;
          margin-left: 10px;
          margin-right: 20px;
          border: 1px solid rgb(189, 189, 189);
          border-radius: 4px;
        }
      }


      .maintenance-block {  

        display: flex;
        align-items: center;
        @include fonts__sans-serif;

        #maintenanceCheckbox{
          display: none;
        }

        input {
          width: 15px;
          height: 15px;
          vertical-align: bottom;
          margin-left: 10px;
        }

      }


      .publish-block {  
        display: flex;
        align-items: baseline;

        .confirm-p-btn {
          width: 139px;
          vertical-align: bottom;
          margin-right: 20px;
          margin-top: 0px;
          padding: 12px 13px 7px;
          background: limegreen;
          color: #fff;
        }
      }

      .submit-d {
        button {
          width: 163px;
        }

        .confirm-mm-btn{
          width: 81px;
          margin-left: 10px;
          padding: 5px 0px 0px;
          background: #cf1b1b;
          color: #fff;
          line-height: 17px;
        }
      }
    }

    // .select{
    //   background-color: lightcoral;
    // }
    // .maintenance{
    //   background-color: lightslategray;
    // }
    .publish{
      padding: 0px 15px 0px;
      height: 60px;
    }
  }
}