@import "../colors.scss";
@import "../fonts.scss";

.not-found {
    margin-bottom: 2rem;
    max-width: 780px;
    margin: 0 auto;
    padding: 10px;
    min-height: 60vh;

}

.loading{
    display: flex;
    align-items: center;
    justify-content: center;
}