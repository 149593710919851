

  .partner {
    display: block;
  }
  


  ul {
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin: 0 3px;
    }
  }