@import "../../colors.scss";
@import "../../fonts.scss";


.payment-success{
    // position: fixed;
    top: 0;
    right: 0;
    background: #f8f8f8;
    padding: 4rem;
    width: 100%;
    // height: 100vh;
    // overflow: auto;
  
    @media only screen and (max-width: 600px) {
      padding: 10px;
    }
  
    .cart-row {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      background-color: #fff;
      box-shadow: var(--shadow-default, 0 20px 24px -20px rgba(0, 0, 0, .1));
      margin: 0 0 2.5rem;
  
  
      &__media {
        margin: 0;
        width: 65px;
  
        img {
          width: 100%;
        }
      }
  
      &__product {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        width: 100%;
  
        &__header {
          @include fonts__sans-serif;
          display: flex;
          width: 100%;
          justify-content: space-between;
  
          span{
            display: inline-block;
            background: #fbd3d3;
            height: 32px;
            width: 32px;
            line-height: 30px;
            border-radius: 50%;
            cursor: pointer;
  
            &:hover{
              background: #e28a8a;
            }
            svg{
              margin-top: 4px;
              margin-left: 4px;
              color: #9c2121;
            }
          }
  
          button {
            width: 60px;
          }
        }
  
        &__content {
          @include fonts__sans-serif;
          align-items: center;
          display: flex;
          justify-content: flex-end;
          padding-top: 10px;
  
          &__qantity {
  
            &__increment {
              @include fonts__sans-serif;
              margin-right: 20px;
              border: 1px solid #cbcbcb;
              padding: 8px 4px;
  
              span {
                display: inline-block;
                width: 20px;
                height: 20px;
                background: #e7f5fe;
                text-align: center;
                border-radius: 50%;
                margin: 0 10px;
                cursor: pointer;
              }
            }
            p{
              font-size: 12px;
              margin-bottom: 5px !important;
            
            }
          }
  
          p {
            margin: 0;
          }
          .item-total-price{
            min-width: 90px;
            text-align: right;
          }
        }
      }
  
    }
  
    .ps-summary{
      display: flex;
      width: 100%;
      max-width: 980px;
      margin: 15px auto;
      justify-content: space-between;

      @media only screen and (max-width: 600px) {
        display: block;
      }
  
      .summary-fees{
        @include fonts__sans-serif;
        width: 57%;
        padding: 15px;
        background-color: #fff;
        box-shadow: var(--shadow-default, 0 20px 24px -20px rgba(0, 0, 0, .1));
  
        @media only screen and (max-width: 600px) {
          width: 100%;
  
        }
        
  
        &__item{
          display: grid;
          grid-template-columns: 50% 50%;
          padding: 3.2px 0;
          position: relative;
  
          span{
            &:nth-child(2){
              text-align: right;
            }
          }
          .subtotal{
            //color:rgb(176, 176, 176);
          }

          .total{
            font-weight: 700;
          }

          p{
            margin: 0;
            font-weight: 300;
          }
          .headitem{
            //color: #717171;
            font-weight: 700;
          }

          .shipto{

            .add{
              padding-left: 10px;
              p{
                color: #000;
                margin: 3px 0;
                &:first-child{
                  margin-top: 15px;
                }
              }
            }
          }
        }
  

        &__item-with-count{
          display: grid;
          grid-template-columns: 50% 12% 38%;
          padding: 3.2px 0;
          position: relative;
  
          font-size: 14px;
         // color: rgb(191, 191, 191);

          span{
            &:nth-child(2){
              text-align: center;
            }
            &:nth-child(3){
              text-align: right;
            }
          }

          // .subtotal{
          //   color:rgb(176, 176, 176);
          // }

          // .total{
          //   font-weight: 700;
          // }
        }

        button{
          @include fonts__sans-serif;
          margin: 10px 0;
          span{
            vertical-align: middle;
            margin-left: 10px;
          }
        }
  
        .security{
          text-align: right;
          margin-top: 10px;
          margin-bottom: 2rem;
          span{
            vertical-align: super;
  
            a{
              @include fonts__sans-serif;
              text-decoration: none;
              font-size: 12px;
              color: rgb(156, 156, 156);
            }
          }
        }
      }

      .ps-head{
        @include fonts__sans-serif;
        width: 100%;
        padding: 15px;
        background-color: #fff;
        box-shadow: var(--shadow-default, 0 20px 24px -20px rgba(0, 0, 0, .1));
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: 600px) {
          width: 100%;
          margin-right: 0;
        }


        svg{
          width: 70px;
          height: 70px;
          fill: #0bab0b;
        }

        &__txt{
          display: flex;
          flex-direction: column;
          margin-left: 20px;

          h2, p{
            margin: 5px 0 ;
          }
        }
      }

      .ps-box{
        @include fonts__sans-serif;
        width: 41%;
        padding: 15px;
        background-color: #fff;
        box-shadow: var(--shadow-default, 0 20px 24px -20px rgba(0, 0, 0, .1));

        @media only screen and (max-width: 600px) {
          width: 100%;
          margin-right: 0;
        }

        &__item{
          display: grid;
          grid-template-columns: 20% 80%;
          padding: 5px 0;
          position: relative;

          .cityinput{
            align-items: center;
            background-color: hsl(0, 0%, 100%);
            border-color: hsl(0, 0%, 80%);
            border-radius: 4px;
            border-style: solid;
            border-width: 1px;
            cursor: default;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            min-height: 36px;
            padding: 0 10px;
            outline: 0!important;
            position: relative;
            -webkit-transition: all 100ms;
            transition: all 100ms;
            box-sizing: border-box;
          }

          .select-shipping-dropdown{
            width: 100%;
            max-width: 500px;
            margin:0 auto;
            a{
              text-decoration: none !important;
            }
            @media only screen and (max-width: 600px) {
              max-width: 95%;
              padding-bottom: 10px;
            }
          }

          span{
            &:nth-child(2){
              text-align: right;
            }
          }
          .subtotal{
           // color:rgb(176, 176, 176);
          }

          .total{
            font-weight: 700;
          }
        }


        &__item-with-count{
          display: grid;
          grid-template-columns: 10% 70% 20%;
          padding: 3.2px 0;
          position: relative;
          padding: 10px;
          margin: 10px 0;
          border: 1px solid #c4c4c4;
          font-size: 16px;
          border-radius: 3px;
          cursor: pointer;
          
          span{
            &:nth-child(2){
              text-align: left;
            }
            &:nth-child(3){
              text-align: right;
            }
          }

          &:hover{
            border: 1px solid #ebb030;
          }

          div{
            .sh-detail{
              font-size: 13px;
              margin-top: 5px;
            }
          }
          // .subtotal{
          //   color:rgb(176, 176, 176);
          // }

          // .total{
          //   font-weight: 700;
          // }
        }
        
        .note{
          font-size: 14px;
        }
        
      }
    }

    .checkout-place-order{
      display: flex;
      width: 100%;
      max-width: 980px;
      justify-content: space-around;

      @media only screen and (max-width: 600px) {
        display: block;
      }
    }

    .cart-continue{
        text-decoration: none;
        display: block;
        span{
            vertical-align: text-top;

            svg{
                transform: rotate(180deg);
            }
        }
        margin-bottom: 20px;
    }
  }

.empty-cart{
    display: flex;
    justify-content: center;
    height: 60vh;
    align-items: center;
    flex-direction: column;

    button{
        width: 170px;
        span{
            vertical-align: middle;
            margin-right: 10px;

            svg{
                transform: rotate(180deg);
            }
        }
    }
}




#shipping-checkbox{
  width: 21px;
    height: 22px;
    border: 1px solid;
    display: inline-block;

    span{
      vertical-align: sub;
      margin: 0 0 0 2px;

      svg{
        width: 14px;
        height: 14px;
      }
    }

}




#payments{
  margin: 3rem auto;

  .shipping-fees{
    button{
      span{
        margin-left: 10px;
        vertical-align: middle;
      }
    }
  }

  .summary-fees{
    box-shadow: none;
  }
}


#edit-cart-btn{
  background: none;
  padding: 8px;
  width: 36px;
  height: 40px;
  span{
    margin-left: 0;

    svg{
      &:hover{
        fill: $color_brand_gold;
      }
    }
  }
}

.css-26l3qy-menu{
  max-height: 300px !important;
  overflow: auto;
}

.paypal-button-container{
  width: 140px;
}

.paypal-button-row{
  width: 140px !important;
}


.paypal-button-container {
  min-width: 120px;
  max-width: 120px;
}


.paypal-button-row  .paypal-button-number-0 .paypal-button-layout-vertical .paypal-button-shape-rect .paypal-button-number-multiple .paypal-button-env-sandbox .paypal-button-color-gold .paypal-button-text-color-black .paypal-logo-color-blue {
  width: 120px;
}


