@import "../../colors.scss";


.thumb-loader{

  min-height: 246px;

  @media only screen and (max-width: 600px) {
    min-height: 160px;
  }


  .load-wraper{
    position: relative;
    height: 252px;
   // width: 252px;
    max-width: 252px;
    background-color: #fdfdfd;// rgb(211,211,211);
    overflow: hidden;
    border: 1px solid #f2f2f2;

    img{
      width: 50px !important;
      top: 35%;
      position: relative;
      border: 1px solid #e8e8e8;
    }

    @media only screen and (max-width: 600px) {
      height: 26vh;
      width: 100%;
    }
  

    
    // border-radius: 5px;
  }
  .activity{
    position: absolute;
    left: -45%;
    height: 100%;
    width: 45%;
    background-image: linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
    background-image: -moz-linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
    background-image: -webkit-linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
    animation: loading 1s infinite;
    z-index: 45;
  }
  
  @keyframes loading {
    0%{
      left: -45%;
    }
    100%{
      left: 100%;
    }
  }

  // @media only screen and (max-width: 600px) {
  //   background-color: #000;
  // }

}

