@import "../../colors.scss";


.image-loader {

  position: relative;

  .img-conts, .g-img-conts{
    position: relative;
    height: 520px;
    width: 520px;
    overflow: hidden;

    @media only screen and (max-width: 600px) {
      height: 95vw;
      width: 95vw;
    }

    .hero, .g-hero {
      // width: 100%;
      // max-width: 580px;
      max-width: 100%;
      max-height: 100%;
    }

    .video-play-btn{
      position: absolute;
      top: 0; left: 0; bottom: 0; right: 0;
      cursor: pointer;
      margin: auto;
      width: 80px;
      height: 80px;
      background: #00000073;
      border-radius: 50%;


      svg{
        color: #ffffff;
        width: 80px;
        height: 80px;
        filter: drop-shadow(2px 1px 1px rgb(0 0 0 / 0.4));

        &:hover{
          color: $color_orange;
        }
      }
    }
  }

  .img-conts-option-loaded{

    //width: 100%;
    width: 520px;
    @media only screen and (max-width: 600px) {
      max-width: 95vw;
    }
  }

  .img-conts-option-unloaded{

    width: 0;
    max-width: 520px;
    
    @media only screen and (max-width: 600px) {
      max-width: 95vw;
    }
  }

  .thumbs {
    display: flex;
    justify-content: center;
    max-width: 580px;
    margin: 10px auto 0;
    padding: 0;
    background: #f2f2f2;
    border-top: 1px solid #b8b8b8;

    .img-cont {

      padding: 10px 5px 5px;

      img {
        border: 1px solid #ffffff;
        cursor: pointer;
        max-width: 80px;
        width: 100%;
        box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%);

        &:hover{
          opacity: 0.8;
        }
      }
    }

  }

  #variant-img{
    display: block;
    width: 100%;
    /* height: auto; */
    position: absolute;
    top: 0;
  }

  .load-wraper {
    position: relative;
    height: 580px;
    width: 800px;
    max-width: 520px;
    background-color: #fdfdfd; // rgb(211,211,211);
    overflow: hidden;
    border: 1px solid #f2f2f2;
    left: 0;

    img {
      width: 50px !important;
      top: 40%;
      left: 43%;
      
      position: relative;

      @media only screen and (max-width: 600px) {
        left: 43%;
      }
    }


    @media only screen and (max-width: 600px) {
      height: 43vh;
      width: 100%;
      top: 39%;
      left: 0;
      position: relative;
    }

  }

  .activity {
    position: absolute;
    left: -45%;
    height: 100%;
    width: 45%;
    background-image: linear-gradient(to left, rgba(251, 251, 251, .05), rgba(251, 251, 251, .3), rgba(251, 251, 251, .6), rgba(251, 251, 251, .3), rgba(251, 251, 251, .05));
    background-image: -moz-linear-gradient(to left, rgba(251, 251, 251, .05), rgba(251, 251, 251, .3), rgba(251, 251, 251, .6), rgba(251, 251, 251, .3), rgba(251, 251, 251, .05));
    background-image: -webkit-linear-gradient(to left, rgba(251, 251, 251, .05), rgba(251, 251, 251, .3), rgba(251, 251, 251, .6), rgba(251, 251, 251, .3), rgba(251, 251, 251, .05));
    animation: loading 1s infinite;
    z-index: 45;
  }

  @keyframes loading {
    0% {
      left: -45%;
    }

    100% {
      left: 100%;
    }
  }

  // @media only screen and (max-width: 600px) {
  //   background-color: #000;
  // }

}