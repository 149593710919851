@import "../../colors.scss";
@import "../../fonts.scss";


.popular-grid {
  width: 100%;
  max-width: 1280px;
  margin: 3rem auto;
  position: relative;

  @media screen and (max-width: 600px) {
    margin: 2rem auto 0;
  }

  .previous-btn {
    position: absolute;
    top: 44%;
    left: 20px;
    z-index: 2;
    cursor: pointer;

    svg {
      transform: rotate(180deg);
      width: 40px;
      height: 40px;
      fill: #e8e8e8;
      fill: #e8e8e8;
      stroke: #535353;
    }

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  .next-btn {
    cursor: pointer;
    position: absolute;
    top: 44%;
    right: 20px;
    z-index: 2;

    svg {
      width: 40px;
      height: 40px;
      fill: #e8e8e8;
      fill: #e8e8e8;
      stroke: #535353;

    }

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  h1 {
    @include fonts__sans-serif;
    color: $color_orange;
    font-size: 2rem;
    padding: 10px;
    margin: 0;

    @media screen and (max-width: 600px) {
      font-size: 1.3rem;
    }
  }

  .holder {
    width: 100%;
    margin: 0 auto;
    max-width: 1280px;
    white-space: nowrap;
    //overflow: scroll;
    scroll-behavior: smooth;
    overflow-x: hidden;

    @media screen and (max-width: 600px) {
      overflow-x: auto;
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }


    .grid-container {
      position: relative;
      margin: 10px 10px 3rem;
      display: inline-flex;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(1, 15vw);
      grid-gap: 20px;
      box-sizing: border-box;
      transition: left 0.3s ease-out;

      @media screen and (min-width: 1280px) {
        grid-template-rows: repeat(1, 200px);
      }

      @media screen and (max-width: 600px) {
        // grid-template-columns : repeat(2,1fr);
        // grid-template-rows:repeat(2, 195px);
        margin: 10px 10px 0;
      }
    }
  }

  .grid_image {
    width: 100%;
    // height:100%;
    object-fit: cover;
    transition: 0.5s ease-in;
    border-radius: 9px;
    border: 1px solid #e8e8e8;

    @media screen and (max-width: 600px) {
      //max-height: 131px;
    }
  }

  .grid_item {
    position: relative;
    /*   overflow: hidden will prevent the image scaling to exceed the specified width. It will expand on back */
    // overflow: hidden; 
    text-decoration: none;
    margin: 0;
    padding: 0;
    border-radius: 5px;

    @media screen and (max-width: 600px) {
      margin: 0 0 20px 0;
    }


    .price, .g-price {

      @include fonts__sans-serif;
      font-size: 24px;
      color:$color_product_price;
      font-weight: 700;
      @media only screen and (max-width: 600px) {
          font-size: 20px;
      }

      sup {
        font-size: 14px;
        font-weight: 700;
        margin-right: 1px;
        @media screen and (max-width: 600px) {
          vertical-align: sub;
        }
      }

      span {
        font-size: 12px;
      }
    }

    p {
      @include fonts__sans-serif;
      font-weight: 300;
      font-size: 16px;

      // position: absolute;
      //background: rgb(0 0 0 / 65%);
      padding: 5px 0 0;
      color: rgb(27, 27, 27);
      top: 0;
      margin: 0;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 194px;

      @media screen and (max-width: 1280) {
        width: 220px;
        font-size: 13px;
      }

      @media screen and (max-width: 768px) {
        width: 130px;
      }

      @media screen and (max-width: 600px) {
        width: 40vw;
        font-size: 13px;
      }




      // &:after{
      //   content:"";
      //   display:block;
      //   border-bottom: 1px solid;
      //   margin-bottom: 3px;
      // }
    }
  }

}