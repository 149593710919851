
/*
  Fonts

  The file assumes it will be compiled to a CSS file that sits like so in the project's directory 
  hierarchy:
  //@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

*/

// Font faces using google fonts
$ptSansRegular:'https://fonts.googleapis.com/css2?family=PT+Sans&display=swap';
$ptSansBold:'https://fonts.googleapis.com/css2?family=PT+Sans:wght@700&display=swap';


//'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap';//
//'https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=PT+Serif:wght@700&display=swap';//
$ptSerifRegular: 'https://fonts.googleapis.com/css2?family=PT+Serif&display=swap';
$ptSerifBold:'https://fonts.googleapis.com/css2?family=PT+Serif:wght@700&display=swa''p';

@mixin fonts__serif() {
  //font-family: 'Open Sans', 'Trebuchet MS', 'Arial Narrow',sans-serif;
  font-family: 'PT Serif', Georgia, Times, 'Times New Roman', serif;
}

@mixin fonts__sans-serif() {
  font-family: 'PT Sans', 'Trebuchet MS', 'Arial Narrow', sans-serif;
}

//NOT AVAILABLE IN GOOGLE WEBFONT
// @mixin fonts__serif-heading() {
//   font-family: 'Abril Titling Bold', 'Book Antiqua', Palatino, 'Palatino Linotype', 'Palatino LT STD', Georgia, serif;
// }
