@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");
@import "../../../colors.scss";
@import "../../../fonts.scss";

.process-orders {


    margin: 0 auto;
    max-width: 1256px;

    .left-side {
        margin: 1rem 0.5rem 1rem 1rem;

        .filter-block {
            display: flex;
            align-items: baseline;

            p {
                margin-top: 0;
            }

            .filter {
                margin: 0 10px;
            }

            .search {}

        }

        // .loader {
        //     margin: 0 auto;
        //     height: 300px;
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        // }

        .gr-row {
            flex-direction: row;
        }

        .gr-column {
            flex-direction: column;

        }

        .form-group {
            margin: 0;
            flex-wrap: wrap;
            padding: 0;
            font-size: 14px;
            display: flex;

            p {
                margin: 0 60px 0 0;
                min-width: 20%;
            }

            background-color: #dde0e1;

            &:nth-child(odd) {
                background-color: #eeeeee;
            }

            &:last-child {
                border-bottom: 1px solid #dbdbdb;
            }

            .form-item {
                padding: 10px 0;
                margin: 0 10px;
                width: 100%;
                /* min-width: 300px; */
                display: flex;
                justify-content: start;
                cursor: pointer;

                .time-elapsed {
                    font-weight: 700;
                    font-size: 12px;
                    font-style: italic;
                    border: 1px solid;
                    padding: 2px;
                    border-radius: 2px;
                }

                input[type=checkbox] {
                    pointer-events: none;

                }


                label {
                    font-size: 13px;
                }

                .required {
                    color: rgb(195, 6, 6);
                    display: inline-block;
                    margin-right: 5px
                }

                input[type=text],
                input[type=number],
                select,
                textarea {
                    @include fonts__sans-serif;
                    background-color: hsl(0deg, 0%, 100%);
                    border-color: hsl(0deg, 0%, 80%);
                    border-radius: 4px;
                    border-style: solid;
                    color: rgb(70, 70, 70);
                    border-width: 1px;
                    min-width: 100px;
                    min-height: 36px;
                    padding: 10px;
                    box-sizing: border-box;
                    width: 100%;

                    @media only screen and (max-width: 600px) {
                        min-width: 100%;

                    }
                }

                input[type=checkbox] {
                    width: 20px;
                    height: 20px;
                    padding: 10px;
                    border: 1px solid;
                    margin: 10px 0;
                }

                input[type=text] {
                    max-width: 816px;
                }

                // textarea {
                //     min-width: 930px;
                // }

                select {
                    width: 200px;
                }

                input[type=number] {
                    width: 100px;
                    max-width: 819px;
                }


                .form-item-number {
                    width: 120px;
                }

                .form-input-display {
                    @include fonts__sans-serif;
                    background-color: hsl(0deg, 0%, 100%);
                    border-color: hsl(0deg, 0%, 80%);
                    border-radius: 4px;
                    border-style: dashed;
                    color: rgb(209, 88, 12);
                    border-width: 1px;
                    min-width: 100px;
                    min-height: 36px;
                    padding: 10px;
                    box-sizing: border-box;
                    margin: 0;
                }


                .add-button {
                    background-color: rgb(192, 192, 192);
                    width: 100%;
                    width: 20px;
                    padding: 5px 12px 5px 6px;
                    text-align: center;
                    line-height: 11px;
                    border-radius: 3px;
                    margin: 0 5px;
                }


                .variant-groups {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;

                    .variants {
                        display: flex;
                        flex-direction: column;

                        .variant-name {
                            margin-top: 5px;
                            display: flex;

                            input {

                                &:first-child {
                                    margin-right: 10px;
                                }

                                &:nth-child(2) {
                                    width: 40% !important;
                                }
                            }

                        }

                        .variant-item {

                            input {
                                margin-left: 30px;
                                width: 300px;
                                min-width: 300px;
                            }

                        }
                    }
                }

                .html-description {
                    background: #fff;
                    padding: 20px;
                    height: 400px;
                    max-width: 860px;
                    overflow: scroll;
                    margin: 20px 0;
                    border: 1px solid #cccccc;
                    border-radius: 5px;
                    font-size: 14px;

                    li {
                        font-size: 16px;
                        line-height: 23px;
                    }
                }

                .submit-btn {
                    width: 260px;
                    max-width: 260px;

                }

                .confirm-btn {
                    @include fonts__sans-serif;
                    height: 39px;
                    width: 116px;
                }


            }


            .order-header {
                width: 100%;
                margin: 0;
                padding: 10px;
                background: #f0bc16;
            }

            .detail {

                // display: flex;
                border: 1px solid #bdbdbd;
                margin: 10px;
                border-radius: 4px;
                background: white;
                width: 100%;

                .order-buyer {
                    padding: 15px 15px 0;
                    margin: 10px;
                    width: 70%;

                    p{
                        margin: 5px 0 !important;
                    }
                }

                .order-detail {
                    padding: 15px;
                    margin: 10px;
                    width: 70%;

                    .order-detail-table {
                        border: 1px solid #d2d2d2;
                        padding: 10px;
                        border-radius: 4px;

                        .order-detail-table-loop {

                            padding: 6px;

                            display: flex;

                            .o-name {
                                width: 70%;

                                a {
                                    @include fonts__sans-serif;
                                    font-weight: 700;
                                    text-decoration: none;
                                    color: #000;

                                    &:hover {
                                        color: #987605;
                                    }
                                }
                            }

                            .o-qty {
                                width: 15%;
                            }

                            .o-price {
                                width: 15%
                            }
                        }
                    }

                    p {
                        @include fonts__sans-serif;
                        margin: 0;
                        width: auto;
                        min-width: 300px;
                        //padding: 3px 0;

                        span {
                            font-weight: 700;
                        }
                    }

                    .address {}

                }

                .action-btns {
                    padding: 15px;

                    margin: 10px;
                    background: white;

                    button {
                        margin-right: 10px;
                    }
                }
            }

            .break {
                flex-basis: 100%;
                height: 0;
            }
        }


        &:nth-child(2) {
            background-color: #000000 !important;
        }




    }

}