
.lds-ring {
	display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
    background: rgb(255 255 255 / 63%);
    padding: 5px;
    border-radius: 50%;
    border: 3px solid #009cde;
    margin: 0 0 31px 0;
	div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 25px;
		height: 25px;
		margin: 0px;
		border: 3px solid #013087;
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: #013087 transparent transparent transparent;
		&:nth-child(1) {
			animation-delay: -0.45s;
		}
		&:nth-child(2) {
			animation-delay: -0.3s;
		}
		&:nth-child(3) {
			animation-delay: -0.15s;
		}
	}

}


.lds-ring-reg{
	display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    background:none;
	border: none;
    padding: 3px;
    border-radius: 50%;
    /* border: 3px solid #009cde; */ 
    margin: 0;

	div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 15px;
		height: 15px;
		margin: 0px;
		border: 2px solid #2e2e2e;
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: #343434 transparent transparent transparent;
	}
}


@keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }



.ring-white {
	div {
		border: 3px solid #fff;
		border-color: #fff transparent transparent transparent;
	}
}
.ring-black {
	div {
		border: 3px solid #423535;
		border-color: #423535 transparent transparent transparent;
	}
}

.ring-blue {
	div {
	border: 3px solid #013087;
	border-color: #013087 transparent transparent transparent;
  }
}