@import "../../colors.scss";
@import "../../fonts.scss";


.cart {
    // position: fixed;
    top: 0;
    right: 0;
    background: #f8f8f8;
    padding: 4rem;
    width: 100%;
    // height: 100vh;
    // overflow: auto;
    margin: 0 auto;
    max-width: 1256px;
  
    @media only screen and (max-width: 600px) {
      padding: 10px;
    }
  
    .cart-row {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      background-color: #fff;
      box-shadow: var(--shadow-default, 0 20px 24px -20px rgba(0, 0, 0, .1));
      margin: 0 0 2.5rem;
  
  
      &__media {
        margin: 0;
        width: 65px;
  
        img {
          width: 100%;
        }
      }
  
      &__product {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        width: 100%;
  
        &__header {
          @include fonts__sans-serif;
          display: flex;
          width: 100%;
          justify-content: space-between;


          p{
            margin: 0;
          }
  
  
          span{
            display: inline-block;
            background: #fbd3d3;
            height: 32px;
            width: 32px;
            line-height: 30px;
            border-radius: 50%;
            cursor: pointer;
  
            &:hover{
              background: #e28a8a;
            }
            svg{
              margin-top: 4px;
              margin-left: 4px;
              color: #9c2121;
            }
          }
  
          button {
            width: 60px;
          }
        }
  
        &__content {
          @include fonts__sans-serif;
          align-items: center;
          display: flex;
          justify-content: flex-end;
          padding-top: 10px;
  
          &__qantity {

            margin-right: 10px;
            @media only screen and (max-width: 600px) {
              margin-right: 5px;

            }

            .var-name{
              border: 1px solid #cbcbcb;
              padding: 11px 4px 11px;
              min-width: 41px;
              text-align: center;
              /* min-height: 21px; */
              border-radius: 3px;
              box-sizing: border-box;
              margin-bottom: 0 !important;
            }
  
            &__increment {
              @include fonts__sans-serif;
              margin-right: 20px;
              border: 1px solid #cbcbcb;
              padding: 8px 4px;
              border-radius: 3px;

              @media only screen and (max-width: 600px) {
                margin-right: 5px;
              }
  
              span {
                display: inline-block;
                width: 20px;
                height: 20px;
                background: #e7f5fe;
                text-align: center;
                border-radius: 50%;
                margin: 0 10px;
                cursor: pointer;

                @media only screen and (max-width: 600px) {
                  margin: 0 5px;
                }
              }
            }
            p{
              font-size: 12px;
              margin-bottom: 5px !important;
            
            }
          }
  
          p {
            margin: 0;
          }
          .item-total-price{
            min-width: 90px;
            text-align: right;
            @media only screen and (max-width: 600px) {
              min-width: 0;
            }
          }
        }
      }
    }
  


    .cart-footer{
      display: flex;
      justify-content: flex-end;
  
      .summary-fees{
        @include fonts__sans-serif;
        width: 33.33333%;
        padding: 0 10px;
  
        @media only screen and (max-width: 600px) {
          width: 100%;
  
        }

        &__item{
          display: grid;
          grid-template-columns: 50% 50%;
          padding: 3.2px 0;
          position: relative;
  
          span{
            &:nth-child(2){
              text-align: right;
            }
          }
          .subtotal{
            color:rgb(176, 176, 176);
          }

          .total{
            font-weight: 700;
          }
        }


        .btn-checkout{
          @include fonts__sans-serif;
          margin: 10px 0;
          span{
            vertical-align: middle;
            margin-left: 10px;
          }

          display: flex;
          align-items: center;
        }
        
        a{
          text-decoration: none;
        }

  
        .security{
          text-align: right;
          margin-top: 10px;
          margin-bottom: 2rem;
          span{
            vertical-align: super;
  
            a{
              @include fonts__sans-serif;
              text-decoration: none;
              font-size: 12px;
              color: rgb(156, 156, 156);
            }
          }
        }
      }
    }


    .cart-continue{
        display: flex;
        align-items: center;
        text-decoration: none;
        &:hover{
          text-decoration: underline;
        }
        span{
            // vertical-align: text-top;

            svg{
                transform: rotate(180deg);
            }
        }
    }
  }

.empty-cart{
    display: flex;
    justify-content: center;
    height: 60vh;
    align-items: center;
    flex-direction: column;

    button{
        width: 170px;
        span{
            vertical-align: middle;
            margin-right: 10px;

            svg{
                transform: rotate(180deg);
            }
        }
    }
}