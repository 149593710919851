@import "../../colors.scss";
@import "../../fonts.scss";



.section-header {

  background-color: #000000;

  @media only screen and (max-width: 600px) {
    background-color: #000;
  }

  .header {
    max-width: 1506px;
    margin: 0 auto;
    background-color: $color_header_bg;
    // margin-bottom: 1rem;
    height: 60px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 3rem;

    @media only screen and (max-width: 8400px) {
      padding: 0 1rem;
    }

    @media only screen and (max-width: 600px) {
      padding: 0 10px;
    }

    .login-btn {
      margin: 0 10px;
      @include fonts__sans-serif;
      font-size: 14px;
      margin: 0 10px;
      width: 40%;
      overflow: hidden;
      display: flex;
      justify-content: flex-end;

      a {
        @include fonts__sans-serif;
        color: #d5d5d5;
        text-decoration: none;
        /* width: 128px; */
        /* display: block; */
        text-align: center;
        width: 100%;
        padding: 10px 0;
        cursor: pointer;
        &:hover {
          color: #8d8d8d;
        }
      }
      .signout-btn{
        background: #efefef;
        position: absolute;
        top: 55px;
        right: 0;
        z-index: 9999999;
        /* left: 41px; */
        /* right: 40px; */
        // margin-top: 10px;
        padding: 5px 8px 5px;
        font-size: 15px;
        border-radius: 3px;
        // border: 1px solid #d7d7d7;
        width: 100%;
        max-width: 395px;
        box-shadow: 0 2px 8px rgba(0,0,0,.3);
        display: flex;
        background: #e9b50f;

        @media only screen and (min-width: 1024px) {
          right: 5%;
        }


        @media only screen and (max-width: 600px) {
          top: 55px;
          max-width: 100%;
        }
        
        .sign-underline {
          border-top: 1px solid #d0d0d0;
        }

        a{
          display: block;
          color: #000;
          // margin: 8px 0;
          cursor: pointer;
          &:hover{
            font-weight: 700;;
          }
        }
      }

    }


    .logo {
      width: 193px;
      @media only screen and (max-width: 600px) {
        width: 130px;
      }
    }

    .c-shopping-cart {
      position: relative;
      // min-width: 54px;

      img {
        width: 43px;
      }

      .snipcart-items-count {
        @include fonts__sans-serif;
        display: inline-block;
        vertical-align: top;
        font-size: 15px;
        color: #e6a751;
        font-weight: 700;
        width: 25px;
        height: 25px;
        padding: 3px;
        line-height: 17px;
        /* border-radius: 50%; */
        /* border: 2px solid #fff; */
        text-align: center;
        /* margin-left: 3px; */
        position: absolute;
        top: -3px;
        left: 14px;
      }
    }

    .total {
      font-size: 12px;
      // position: absolute;
      display: inline-block;
    }

  }

  .mobile-search{
    display: flex;
    align-items: baseline;
  }
}



.help-div {

  margin: 0 10px;

  .help {
    @include fonts__sans-serif;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #fff;
    text-decoration: none;


    &:hover {
      background-color: gray;
    }

    a {
      color: #fff;
      text-decoration: none;
      font-size: 15px;
      font-weight: 700;
      padding-top: 2px;
    }

  }
}


.select-site-dropdown {
  @include fonts__sans-serif;
  width: 100%;
  max-width: 750px;
  margin: 0 auto 0 15px;
  z-index: 9;

  a {
    text-decoration: none !important;
  }

  @media only screen and (max-width: 600px) {
    // max-width: 95%;
    margin: 0px auto;
    padding: 0 10px 10px;
  }

  .css-1s2u09g-control {

    .css-1hb7zxy-IndicatorsContainer {
      cursor: pointer;
    }
  }

  .css-s7jdiw-option{
    padding: 0;
    a{
      padding: 10px;
      min-height: 40px;
      display: inline-block;
      width: 100%;
    }
  }

  .css-151mtoe-option{
    padding: 0;
    a{
      padding: 10px;
      min-height: 40px;
      display: inline-block;
      width: 100%;
    }
  }
}

.select-opt {
  padding: 10px;

  @media only screen and (max-width: 600px) {
    padding: 0 10px 10px;
  }
}




@media only screen and (max-width: 600px) {
  .css-1s2u09g-control {
    a {
      text-decoration: none !important;
    }
  }
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  background: #e8b510;
  // padding: 5px !important;
  color: #000 !important;

}


.css-1s2u09g-control {
  border: none;
}

.css-26l3qy-menu {
  margin-top: 0 !important;

  @media only screen and (max-width: 600px) {
    z-index: 999 !important;
    top: 90% !important;
    margin-top: 0 !important;

    a {
      text-decoration: none !important;
    }
  }
}