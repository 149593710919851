@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");
@import "./colors.scss";
@import "./fonts.scss";

* {
  box-sizing: border-box;
}

html {
  @include fonts__serif;
  //font-family: "Noto Sans JP", sans-serif;
}

hr {
  height: 1px !important;
  background-color: rgb(220, 220, 220);
  border: none;
}

body {
  margin: 0;
  background-color: $color_body_bg;
  color: #393939;
}

main {
  text-align: center;
}

h1,
h2,
h3,
p {
  color: #3d3d3d;
}


.Toastify__toast-body {
  align-items: flex-start;
}

.goond-toast {

  display: flex;
  flex-direction: column;

  p {
    @include fonts__serif;
    font-size: 14px;
  }

  .btns {
    display: flex;
    justify-content: space-evenly;

    button {
      max-width: 60px;
      padding: 3px 5px;
      font-size: 13px;
    }
  }


}


/** privacy **/
.rte-box {
  background: #fff;
  margin-bottom: 3rem;
}

.rte {
  padding: 15px 25px;
  font-size: 14px;
  line-height: 21px;
  color: #898989;
  text-align: left;
}

.rte h3 {
  color: #000;
}

.rte h1 {
  color: #000;
  margin-bottom: 3rem;
}


button {
  cursor: pointer;
  width: 49%;
  max-width: 180px;
  margin: 8px 0;
  display: inline-block;
  border: none; //1px solid #118ea5;
  border-radius: 4px;
  background-color: $color_brand_gold;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #000;
  font-size: 14px;
  text-transform: uppercase;

  span {
    @include fonts__sans-serif;
    vertical-align: super;
  }

  &:hover {
    background-color: $color_brand_gold_over;
    color: #fff;
  }
}




.desktop-only {
  display: block;

  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.mobile-only {
  display: none;

  @media only screen and (max-width: 600px) {
    display: block;
  }
}

.help-page {

  .rte {
    color: #000;
    margin: 0;
    padding: 10px;
    margin: 0 4rem;

    @media only screen and (max-width: 600px) {
      margin: 0;
    }
  }

  form {

    label {
      @include fonts__sans-serif;
      display: block;
    }

    .form-group {
      padding:20px;
     // margin: 10px 0;
      display: flex;
      flex-direction: column;
    }

    .required {
      color: rgb(195, 6, 6);
      display: inline-block;
      margin-right: 5px
    }


    input[type=text],
    textarea {
      @include fonts__sans-serif;
      background-color: hsl(0deg, 0%, 100%);
      border-color: hsl(0deg, 0%, 80%);
      border-radius: 4px;
      border-style: solid;
      color: rgb(70, 70, 70);
      border-width: 1px;
      min-width: 400px;
      min-height: 36px;
      padding: 10px;
      box-sizing: border-box;

      @media only screen and (max-width: 600px) {
        min-width: 100%;
        width: 100%;
      }
    }

  }
}


/**  SNIP CART RELATED */
//@at-root

.snipcart-modal__close-title {

  @include fonts__sans-serif;
}

.snipcart-cart-header__title {

  @include fonts__sans-serif;
  font-size: 19px;

}

.snipcart-item-line__title {

  @include fonts__sans-serif;
}


.snipcart__font--subtitle {
  @include fonts__sans-serif;
}

.grecaptcha-badge {
  display: none;
}

#error {
  background: #cc0808;
  color: #fff;
  max-width: 300px;
  width: 100%;
  text-align: center;
  padding: 8px 5px;
  border-radius: 4px;
}


.error-modal {
  display: flex;
  height: 510px;
  align-items: center;

  &__box {
    max-width: 400px;
    margin: 0 auto;
    padding: 10px;
    background: #9d0505;
    border-radius: 4px;

    p{
      @include fonts__sans-serif;
      font-size: 14px;
      color: #fff;

      &:first-child{
        font-size: 17px;
      }
    }
  }
}



.loading-screen{
  width: 100%;
  height: 80vh;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
}
