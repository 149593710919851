@import "../../colors.scss";

.footer {
  margin-top: 5rem;
  margin-bottom: 0;
  color: #898989;
  background-color: $color_footer_bg;

  align-items: initial;
  padding: 3rem 3rem 1rem;
  flex-direction: column;
  justify-content: space-between;

  height: 300px;
}

.fc-a {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &:first-child {
    margin-bottom: 20px;
  }
  
  .info {
    li {
      display: block;
      padding: 0 0 5px 0;

      a {
        display: block;
        font-size: 13px;
        color: #107fa8
      }
    }
  }


}




.fc-b{
  display: flex;
  justify-content: space-between;
  width: 100%;

    ul {
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin: 0 3px;
    }
  }

  .partner {
    display: block;
  }
  

  &:first-child {
    margin-bottom: 20px;
  }

  .copy-right {
    font-size: 11px;

    a {
      color: #898989;
    }

  }

}







// /** mobile */
@media only screen and (max-width: 700px) {
  .footer {
    padding: 0 10px;
  }

  .fc-a {
    flex-direction: column;
    align-items: left;
    padding: 20px 0;
  }
  .fc-b {
    flex-direction: column;
    align-items: center;
  }

  .fc-b{
    padding: 10px 0 2rem;
    .copy-right {
      text-align: center;
      margin-bottom: 10px;
      .partner {
        margin-top: 10px;
      }
    }
  }


  .card-container .card {
    width: calc(100% / 2 - 20px);

  }

}