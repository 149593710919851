@import "../../colors.scss";
@import "../../fonts.scss";

.price, .g-price {

    @include fonts__sans-serif;
    margin: 0 0 20px;
    font-weight: 700;
    text-align: initial;
    color: #333;

    .sup {
      font-size: 15px;
      font-weight: 700;
      margin-right: 1px;
    }

    .prc{
      margin-right: 8px;
      font-size: 1.7rem;
    }


    .foreign-price{
      background: #d02323;
      font-size: 14px;
      color: #fff;
      padding: 1px 10px;
    }

    .transaction-note{
      font-size: 12px;
      color: rgb(138, 138, 138);
      font-weight: 300;
      margin-left: 2px;
    }

    .foreign-discount{
      font-size: 12px;
      color: #00a800;
      margin-left: 2px;
    }

    .attrs {

      font-size: 12px;
      //margin-left: 10px;
      color: $color_rrp_grey;
      display: inline-block;


        /** mobile */
        @media only screen and (max-width: 600px) {
          display: block;
          margin-left: 0;
        }
      
    }

    .rrp{
      text-decoration: line-through; 
    }

    .off {
      font-size: 12px;
      color: $color_rrp;
      margin-left: 5px;
    }
  }
