@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");
@import "../../colors.scss";
@import "../../fonts.scss";

.btns-with-loader {
    width: 100%;
    max-width: 300px;
    .bwl-btn {
        @include fonts__sans-serif;

        padding: 12px 5px;
        line-height: 20px;
        width: 100%;
        span {
            vertical-align: baseline;
        }
    }

    .confirm-btns{
        width: 100%;
        display: flex;
        button{
            margin-right: 20px;
        }
    }
}