@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");
@import "../../../colors.scss";
@import "../../../fonts.scss";

.add-product-page {

    display: flex;
    margin: 0 auto;
    max-width: 1256px;
    

    @media only screen and (max-width: 1217px) {
        flex-direction: column-reverse;
    }

    .left-side {
        margin: 1rem 0.5rem 1rem 1rem;

        width: 70%;

        @media only screen and (max-width: 1217px) {
            margin: 1rem 0 1rem 0;

            width: 100%;
        }
    

        h1 {
            margin: 0 0 0.67em 0;
        }

        .note {
            @include fonts__sans-serif;
            background-color: #ffd9e0;
            padding: 10px;
        }
    }


    .right-side {
        width: 30%;
        position: relative;
        
        margin: 74px 1rem 1rem 0.5rem;
        background: #dee0e1;

        @media only screen and (max-width: 1217px) {
            margin: 74px 0 1rem 0;
            width: 100%;
        }

        @media only screen and (max-width: 1217px) {
            margin: 10px 0 1rem 0;
            z-index: 9999;
            max-width: 300px;
            position: fixed;
            top: 60px;
            right: 0;
            box-shadow: 3px 3px 10px rgba(15, 6, 5, 0.2);
            // display: none;
        }

        @media only screen and (max-width: 660px) {
            width: 100%;
            height: 101vh;
            padding: 0;
            margin: 0;
            top: 0;
            max-width: 100%;
        }
    
        .right-panel-hide-btn{
            cursor: pointer;
            position: sticky;
            top: 0;
            display: none;
            @media only screen and (max-width: 1217px) {
                display: block;
                padding: 10px;
                width: 93px;
                font-size: 12px;
                background: #cecece;
            }
        }


        .right-panel-show-btn{
            cursor: pointer;
            position: fixed;
            top: 7px;
            right: 11px;
            display: none;
            @media only screen and (max-width: 1217px) {
                display: inline-block;
                padding: 10px;
                width: 133px;
                font-size: 12px;
                background: #cecece;
            }
        }

        .content {
            position: sticky;
            top: 0;
        }
    }

    .ali-section {
        padding: 0;
        background: #eeeeee;

        p {
            padding: 10px;
            margin: 5px 0;
            font-weight: 700;
        }

        .form-group {
            .form-item {
                .scrape-btn {
                    width: 170px;
                    height: 38px;
                    margin-top: 16px;
                }

                .error {
                    @include fonts__sans-serif;
                    background: #cc0808;
                    color: #fff;
                    font-weight: 300;
                    font-size: 13px;
                    padding: 8px 10px;
                    border-radius: 4px;
                    margin: 0;
                }
            }
        }
    }

    .add-error {
        @include fonts__sans-serif;
        background: #cc0808;
        color: #fff;
        max-width: 300px;
        width: 100%;
        // text-align: center;
        font-size: 15px;
        padding: 8px 5px;
        border-radius: 4px;
    }


    form {

        display: flex;
        flex-direction: column;

        hr {
            color: red;
        }

        label {
            @include fonts__sans-serif;
            display: block;
        }
    }
}



.gr-row {
    flex-direction: row;
}

.gr-column {
    flex-direction: column;

}




.form-group {
    margin: 0;

    padding: 20px 0;

    display: flex;

    background-color: #dde0e1;

    @media only screen and (max-width: 920px) {
        flex-direction: column;
        padding: 20px 12px;
    }

    &:nth-child(odd) {
        background-color: #eeeeee;
    }

    &:last-child {
        border-bottom: 1px solid #dbdbdb;
    }

    .form-item {

        margin: 0 10px;
        width: 100%;


        @media only screen and (max-width: 660px) {
            margin: 10px 0;
            margin: 10px auto;
            // width: 100%;
        }
    

        label {
            min-height: 12px;
            font-size: 13px;
        }

        .required {
            color: rgb(195, 6, 6);
            display: inline-block;
            margin-right: 5px
        }

        input[type=text],
        input[type=number],
        select,
        textarea {
            @include fonts__sans-serif;
            background-color: hsl(0deg, 0%, 100%);
            border-color: hsl(0deg, 0%, 80%);
            border-radius: 4px;
            border-style: solid;
            color: rgb(70, 70, 70);
            border-width: 1px;
            min-width: 100px;
            min-height: 36px;
            padding: 10px;
            box-sizing: border-box;
            width: 100%;

            @media only screen and (max-width: 600px) {
                // min-width: 100%;

            }
        }

        input[type=checkbox] {
            width: 20px;
            height: 20px;
            padding: 10px;
            border: 1px solid;
            margin: 10px 0;
        }

        input[type=text] {
            max-width: 816px;
        }

        // textarea {
        //     min-width: 930px;
        // }

        select {
            width: 200px;

            @media only screen and (max-width: 660px) {
                width: 100%;
            }
        }

        input[type=number] {
            width: 100px;
            max-width: 819px;

            @media only screen and (max-width: 660px) {
                width: 100%;
            }
        }


        .form-item-number {
            width: 120px;
        }

        .form-input-display {
            @include fonts__sans-serif;
            background-color: hsl(0deg, 0%, 100%);
            border-color: hsl(0deg, 0%, 80%);
            border-radius: 4px;
            border-style: dashed;
            color: rgb(209, 88, 12);
            border-width: 1px;
            min-width: 100px;
            min-height: 36px;
            padding: 10px;
            box-sizing: border-box;
            margin: 0;
        }


        .add-button {
            background-color: rgb(192, 192, 192);
            width: 100%;
            width: 20px;
            padding: 5px 12px 5px 6px;
            text-align: center;
            line-height: 11px;
            border-radius: 3px;
            margin: 0 5px;
        }


        .variant-groups {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            .variants {
                display: flex;
                flex-direction: column;

                .variant-name {
                    margin-top: 5px;
                    display: flex;

                    input {

                        &:first-child {
                            margin-right: 10px;
                        }

                        &:nth-child(2) {
                            width: 40% !important;
                        }
                    }

                    img{
                        width: 40px;
                        margin: 0 10px;
                        border-radius: 3px;
                    }

                }

                .variant-item {

                    input {
                        margin-left: 30px;
                        width: 300px;
                        min-width: 300px;
                    }

                }
            }
        }

        .html-description {
            background: #fff;
            padding: 20px;
            height: 400px;
            max-width: 860px;
            overflow: scroll;
            margin: 20px 0;
            border: 1px solid #cccccc;
            border-radius: 5px;
            font-size: 14px;

            li {
                font-size: 16px;
                line-height: 23px;
            }
        }

        .submit-btn {
            @include fonts__sans-serif;
            height: 39px;
            width: 133px;
        }

        .ai-btn {
            width: 230px;
            max-width: 300px;
        }

        .confirm-btn {
            @include fonts__sans-serif;
            background: #cf1b1b;
            color: #fff;
            height: 39px;
            width: 116px;
        }

        .ai-usage{
            @include fonts__sans-serif;
            margin: 5px 0;
            font-size: 14px;
        }


    }

    .side-panel {
        margin: 2px 10px;
        width: 94%;
        background: lightblue;
        padding: 0px 8px;

        label {
            @include fonts__sans-serif;
            display: inline-block;
        }

        input[type=checkbox] {
            vertical-align: sub;
            margin: 10px;
        }
    }

}

.update-div {
    @include fonts__sans-serif;
    width: auto !important;
    margin: 10px !important;
    .select-site-dropdown-side-panel {
        // height: 30px;
        margin-bottom: 10px;


        .css-tlfecz-indicatorContainer,
        .css-1gtu0rj-indicatorContainer {
            background: #ffffff;
            padding: 5px !important;
        }
    }
}

.highlight-on-error{
    border: 1px solid red !important;
}


.sun-editor{
    // width: 91vw !important;

    .se-btn {

        @media only screen and (max-width: 660px) {

            width: 27px;
            height: 31px;
        }

    }
}