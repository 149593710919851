@import "../../colors.scss";
@import "../../fonts.scss";

.top-banner {
  background-color: $color_top_banner_bg;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 5px;
  width: 100%;

  .container {
    max-width: 1060px;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0 3rem;
    text-decoration: none;

    @media only screen and (max-width: 600px) {
      padding: 0 10px;
    }

    .logo {
      width: 195px;
    }

    img {
      width: 50px;
    }

    .content {
      display: flex;
      display: flex;
      align-items: center;
      width: 86%;

      .desc {
        font-size: 13px;
        padding: 0 10px;
        text-align: left;
        overflow: hidden;
        height: auto;


        line-height: 1.3em;
        max-height: 2.5em;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        @media only screen and (max-width: 600px) {
          height: 44px;
        }
      }

      .price, .g-price {
        @include fonts__sans-serif;
        font-size: 20px;
        color: rgb(179, 10, 10);
        padding: 0 20px 0 0;

        @media only screen and (max-width: 600px) {
          padding: 0 4px;
          font-size: 17px;
        }

        // font-weight: 700;
      }

      .buy-btn {
        @include fonts__sans-serif;
        max-width: 74px;
        font-size: 12px;
        background: #eeb90f;
        padding: 8px;
        color: #000;

        @media only screen and (max-width: 600px) {
          display: none;
        }
      }
    }

  }
}