@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");
@import "../../../colors.scss";
@import "../../../fonts.scss";

.view-orders{
    width: 100%;
    padding: 1rem 4rem;

    @media only screen and (max-width: 600px) {
        padding: 10px;
    }

    .loader{
        display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    }

    iframe{
        width: 100%;
        height: 90%;
        border: none;
    }

    h1{
        @include fonts__sans-serif;
    }



    table {
        width: 100%;
        border-collapse: collapse;
        @include fonts__sans-serif;
        overflow-x: auto; /* Enable horizontal scrolling on small screens */
      }
      
      thead {
        background-color: #f2f2f2;
      }
      
      th, td {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
        font-size: 15px;

        @media only screen and (max-width: 600px) {
            padding: 12px;
        }
      }
      
      tr:nth-child(even) {
        background-color: #f2f2f2;
      }
      
      tr:hover {
        background-color: #ddd;
      }
      
      th {
        background-color: #4CAF50;
        color: white;
      }
      

    .yellow-bg {
        background-color: yellow;
      }

    @keyframes blink-red {
        0%, 100% { background-color: inherit; }
        50% { background-color: red; }
      }
      
      @keyframes blink-green {
        0%, 100% { background-color: inherit; }
        50% { background-color: green; }
      }
      
      .blinking-cell-red {
        animation: blink-red 1s infinite;
      }
      
      .blinking-cell-green {
        animation: blink-green 3s infinite;
      }
      
}