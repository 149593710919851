@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");
@import "../../colors.scss";
@import "../../fonts.scss";

.user-account {
    width: 100%;
    max-width: 800px;
    margin: 2rem auto;
    min-height: 55vh;

    .attempt{
        text-align: center;
        max-width: 300px;
        margin: 3rem auto;
        background: rgb(195, 6, 6);
        padding: 10px;
        border-radius: 4px;
        color: #fff;
    }
    
    form {

        display: flex;
        flex-direction: column;

        hr {
            color: red;
        }

        label {
            @include fonts__sans-serif;
            display: block;
        }

        .gr-row {
            flex-direction: column;

            p{
                @include fonts__sans-serif;
                margin:0 0 10px 0;
            }

            @media only screen and (max-width: 600px) {
                flex-direction: column;

            }

            .hor-line{
                width: 100%;
                height: 2px;
                border-top: 1px solid #c9c9c9;
            }

            .ua-item{
                display: flex;
                align-items: baseline;

                a{
                    text-decoration: none;
                    font-size: 14px;

                    &:focus{
                        color: #000;
                    }
                }

                button{
                    margin-left: 20px;
                    max-width: 80px;
                    padding: 5px;
                    @include fonts__sans-serif;
                }
            }

        }

        .gr-column {
            flex-direction: column;
        }

        .form-group {
            margin: 0;

            padding: 20px;

            display: flex;

            background-color: #dde0e1;

            // &:nth-child(odd) {
            //     background-color: #eeeeee;
            // }

            // &:last-child {
            //     border-bottom: 1px solid #dbdbdb;
            // }

            .form-item {

                .login-btn {
                    @include fonts__sans-serif;
                    font-size: 14px;
                    font-weight: 700;
                    background: #eeb90f;
                    padding: 8px 8px 5px;
                    color: #000;
                    width: 100%;
                    max-width: 100%;

                }

                margin: 5px 0 0 0;
                @media only screen and (max-width: 600px) {
                    margin: 0;

                }

                label {
                    font-size: 13px;
                }

                .error{
                    @include fonts__sans-serif;
                    margin: 0;
                    font-size: 12px;
                    color: #fff;
                    background: #cd1c1c;
                    padding: 5px 10px;
                    border-radius: 4px;
                }

                .required {
                    color: rgb(195, 6, 6);
                    display: inline-block;
                    margin-right: 5px
                }

                input[type=text],
                input[type=number],
                input[type=password],
                select,
                textarea {
                    @include fonts__sans-serif;
                    background-color: hsl(0deg, 0%, 100%);
                    border-color: hsl(0deg, 0%, 80%);
                    border-radius: 4px;
                    border-style: solid;
                    color: rgb(70, 70, 70);
                    border-width: 1px;
                    min-width: 100px;
                    min-height: 36px;
                    padding: 10px;
                    box-sizing: border-box;
                    width: 100%;

                    @media only screen and (max-width: 600px) {
                        min-width: 100%;
                        width: 100%;
                    }
                }

                input[type=text] {
                    max-width: 350px;
                    width: 100%;
                
                }

                textarea {
                    min-width: 930px;
                }

                select {
                    width: 200px;
                }

                input[type=number] {
                    width: 100px;
                }


                .form-item-number {
                    width: 120px;
                }

                .form-input-display {
                    @include fonts__sans-serif;
                    background-color: hsl(0deg, 0%, 100%);
                    border-color: hsl(0deg, 0%, 80%);
                    border-radius: 4px;
                    border-style: dashed;
                    color: rgb(209, 88, 12);
                    border-width: 1px;
                    min-width: 100px;
                    min-height: 36px;
                    padding: 10px;
                    box-sizing: border-box;
                    margin: 0;
                }


                .add-button {
                    background-color: rgb(192, 192, 192);
                    width: 100%;
                    width: 20px;
                    padding: 5px 12px 5px 6px;
                    text-align: center;
                    line-height: 11px;
                    border-radius: 3px;
                    margin: 0 5px;
                }


                .variant-groups {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;

                    .variants {
                        display: flex;
                        flex-direction: column;

                        .variant-name {
                            margin-top: 5px;
                        }

                        .variant-item {

                            input {
                                margin-left: 30px;
                                width: 300px;
                                min-width: 300px;
                            }

                        }
                    }
                }

            }

        }
    }
}


.sign-btn {
    margin: 10px 0;
    @include fonts__sans-serif;
    font-size: 14px;
    a{
        @include fonts__sans-serif;
        font-weight: 700;
        color: #0c4eb9;

        &:hover{
            color: #306dcf;
        }
    }
}
.fp-btn {
    margin: 10px 0 0 0;
    @include fonts__sans-serif;
    font-size: 14px;
    a{
        @include fonts__sans-serif;
        color: #0c4eb9;
        &:hover{
            color: #306dcf;
        }
    }
}