@import "../../colors.scss";
@import "../../fonts.scss";


.product-page {
    max-width: 1280px;
    margin: 0 auto;
    padding: 10px;

    //padding-top: 270px;
    // display: flex;
    // flex-flow: row wrap;

    // @media only screen and (max-width: 1280px) {
    //     padding-top: 220px;
    //   }

    // @media only screen and (max-width: 1048px) {
    //     padding-top: 200px;
    //   }

    //   @media only screen and (max-width: 955px) {
    //     padding-top: 162px;
    //   }

    @media only screen and (max-width: 600px) {
        //padding-top: 180px;
    }

    h2{
        margin: 0 0 1rem 0;
        @media only screen and (max-width: 600px) {
            padding: 0 10px;
        }
    }


    .grid-head {
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(1, 25fr);
        grid-template-rows: repeat(1, 25fr);
        margin-bottom: 10px;
        grid-gap: 1px;

        h1{
            z-index: 3;
            @include fonts__sans-serif;
            color: $color_orange;
            font-size: 2rem;
            //padding: 10px;
            margin:0;
            @media screen and (max-width: 600px) {
              font-size: 1.3rem;
            }   
        }

    }


    .grid {
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(4, 25fr);
        grid-template-rows: repeat(1, 25fr);
        margin-bottom: 30px;
        grid-gap: 1px;


        @media only screen and (max-width: 950px) {
            grid-template-columns: repeat(3, 25fr);
            grid-template-rows: repeat(2, 25fr);
        }

        @media only screen and (max-width: 600px) {
            grid-template-columns: repeat(2, 25fr);
            grid-template-rows: repeat(1, 25fr);
        }

        .grid-item {
            //background-color: rgb(216, 216, 216);
            outline: 1px solid rgb(203, 203, 203);
            /* The outline creates the border */
            text-align: center;
            position: relative;
            z-index: 1;
            /* original z-index */
            padding: 1.5rem;
            background: white;

            &:hover {
                outline: 1px solid rgb(1, 188, 126);
                z-index: 9;
                /* You must apply a z-index bigger than the original z-index or else some parts of the outline will be behind other grid elements */
            }

            .card {
                text-decoration: none;

                @media only screen and (max-width: 950px) {
                    // width: calc(100% / 3 - 20px);
                }

                .content {

                    padding: 15px 0 25px 0 ;

                    p {
                        color: #888;
                        padding: 0;
                        margin: 0;
                        font-size: 22px;
                        text-align: left;
                        /* font: 400 1.6rem/2.2rem 'Open Sans script=all', sans-serif; */
                    }

                    .p-name {
                        /* font: 2.8rem/3.4rem 'Bree Serif', serif; */
                        @include fonts__sans-serif;
                        padding: 0;
                        margin: 0 0 5px;
                        font-weight: 300;
                        font-size: 16px;
                        color: #595959;
                        line-height: 20px;
                        min-height: 39px;

                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: normal;

                        @media only screen and (max-width: 600px) {
                            font-size: 13px;
                            line-height: 16px;
                            min-height: 34px;
                        }
    
                        /* letter-spacing: 0.015rem */
                    }
                }


                img {
                    width: 100%;
                    padding: 0;
                    margin: 0;

                }


            }

            @media only screen and (max-width: 600px) {
                padding: 0.5rem;
            }
    


        }


        .more{
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            // outline: none;
            // &:hover{
            //     outline: none;
            // }
            p{
                font-size: 20px;
            }
        }

     


    }


    &:hover .card {
        cursor: pointer;
    }

}