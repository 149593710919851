@import "../../colors.scss";
@import "../../fonts.scss";


.category-grid {
  width: 100%;
  max-width: 1280px;
  margin: 2rem auto 4rem;
  position: relative;

  h1{
    @include fonts__sans-serif;
    color: $color_orange;
    font-size: 2rem;
    padding: 10px;
    margin:0;

    @media screen and (max-width: 600px) {
      font-size: 1.3rem;
    }

  }

  .grid-container {

    margin: 10px 10px 2rem;
    display: grid;
    grid-template-columns : repeat(4,1fr);
    grid-template-rows: repeat(1, 24vw);
    grid-gap: 20px;
    box-sizing:border-box;

    @media screen and (min-width: 1280px) {
      grid-template-rows: repeat(1, 300px);
    }

    @media screen and (max-width: 600px) {
      grid-template-columns : repeat(2,1fr);
      grid-template-rows:none;
      margin: 10px;
    }
  }
  
  .grid_image {
    width:100%;
    height:100%;
    object-fit:cover;
    transition:0.5s ease-in;
  }
  
  .grid_item {
    position: relative;
  /*   overflow: hidden will prevent the image scaling to exceed the specified width. It will expand on back */
    overflow: hidden; 
    margin: 0;
    padding: 0;
    border-radius: 5px;

    p{
      @include fonts__sans-serif;
      font-weight: 300;
      font-size: 13px;
      text-transform: uppercase;
      position: absolute;
      background: rgb(0 0 0 / 65%);
      padding: 8px 10px 5px;
      color: rgb(190, 190, 190);
      top: 0;
      margin:0;

      &:after{
        content:"";
        display:block;
        border-bottom: 1px solid;
        margin-bottom: 3px;
      }
    }
  }

}