@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");


/** -- Colors -- */
$color_body_bg: #fff; // #f1f2f4;// #e0e9f5;
$color_header_bg: #000;
$color_footer_bg: #f1f2f4;
$color_top_banner_bg: #f1f2f4;
$color_brand_gold: #e8b510;
$color_brand_gold_over: #cb9f0e;
$color_homepage_banner:#d9d9d9;
$color_rrp:#d70606;
$color_orange:#f09800;
$color_rrp_grey:#9a9a9a;
$color_grey:#7e7e7e;
$color_product_price: rgb(79, 79, 79);






