@import "../../colors.scss";
@import "../../fonts.scss";


.variants {

  position: relative;

  p{
    @include fonts__sans-serif;
    margin: 15px 0 5px;
    color: #042b5f;
    font-size: 15px;
  }

  .holder{
    display: inline-block;
    width: 60px !important;
    height: 55px !important;
    margin: 4px;
    border-radius: 2px;
    border: 2px solid #cccccc;
    cursor: pointer;

  }
  
  .div-h{
    @include fonts__sans-serif;
    font-size: 13px;
    min-width: 50px;
    text-align: center;
    padding: 5px;
    height: auto;
    vertical-align: middle;

  }
}

.var-idle{
  border: 1px solid #cccccc;
}

.var-active{
  border: 2px solid #f09800;
}
