@import "../../colors.scss";
@import "../../fonts.scss";



.homepage-banner {
  width: 100%;
  // max-width: 1280px;
  margin: 0 auto 2rem;

  height: 500px;
  position: absolute;

  background: rgb(3, 3, 3);
  background: -moz-linear-gradient(180deg, rgba(3, 3, 3, 1) 0%, rgba(208, 161, 12, 1) 0%, rgba(255, 255, 255, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(3, 3, 3, 1) 0%, rgba(208, 161, 12, 1) 0%, rgba(255, 255, 255, 1) 100%);
  background: linear-gradient(180deg, rgba(3, 3, 3, 1) 0%, rgba(208, 161, 12, 1) 0%, rgba(255, 255, 255, 1) 100%);
}


.banner {
  max-width: 1256px;
  display: block;
  width: 100%;
  margin: auto;
  @include fonts__sans-serif;

  .slider-wrapper{
    margin: 5px 0;
    position: relative;
    overflow: hidden;

    ul {
      li {
        margin: 0;
      }
    }
  }

  .slides-container {
    height: 300px; //calc(100vh - 2rem);
    width: 100%;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    list-style: none;
    margin: 0;
    padding: 0;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    @media only screen and (max-width: 705px) {
      height: auto;
    }

    // border: 1px solid #ededed;
  }

  .slide-arrow {
    position: absolute;
    display: flex;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 4rem;
    background-color: white;
    border: none;
    width: 2rem;
    font-size: 3rem;
    padding: 0;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 100ms;

    // &:hover,
    // &:focus {
    //   opacity: 0.3;
    // }
  }

  #slide-arrow-prev {
    left: 0;
    padding-left: 0.25rem;
    border-radius: 0 2rem 2rem 0;

    span {
      display: inline-block;
    }
  }

  #slide-arrow-next {
    right: 0;
    padding-left: 0.75rem;
    border-radius: 2rem 0 0 2rem;

    span {
      display: inline-block;
        position: relative;
        left: -5px;
    }


  }

  .slide {
    width: 100%;
    height: 100%;
    flex: 1 0 100%;

    img {
      width: 100%;
    }

    a {
      text-decoration: none;
    }

    // .content {
    //   display: grid;
    //   grid-template-columns: 50% 50%;

    //   // @media only screen and (max-width: 1048px) {
    //   //   grid-template-columns: 50% 50%;
    //   // }

    //   &__left {
    //     text-align: right;
    //     display: flex;
    //     align-items: center;

    //     img {

    //       max-height: 280px;

    //       @media only screen and (max-width: 1280px) {
    //         max-height: 230px;
    //       }

    //       @media only screen and (max-width: 1048px) {
    //         max-height: 210px;
    //       }

    //       @media only screen and (max-width: 600px) {}

    //     }
    //   }

    //   &__right {

    //     display: flex;
    //     justify-content: center;
    //     flex-direction: column;

    //     &__txt {

    //       width: 100%;
    //       max-width: 400px;

    //       p,
    //       h2 {
    //         margin: 0;
    //         margin-bottom: 10px;
    //       }
    //     }


    //   }
    // }




    // &:nth-child(1) {
    //   background-color: #49b293;
    // }

    // &:nth-child(2) {
    //   background-color: #b03532;
    // }

    // &:nth-child(3) {
    //   background-color: #6a478f;
    // }

    // &:nth-child(4) {
    //   background-color: #da6f2b;
    // }
  }



}